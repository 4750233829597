<template>
  <main id="main">
    <section id="card1" class="card d-flex flex-column align-center">
      <div class="top">
        <page3-attributes
          class="attrs"
          v-if="result.position"
          :img-url="imgUrl"
          :face-top="result.position.top"
          :face-left="result.position.left"
          :face-width="result.position.width"
          :face-height="result.position.height"
          :eye-tag="result.eye.tag"
          :eye-keyword="result.eye.keyword"
          :brow-tag="result.brow.tag"
          :brow-keyword="result.brow.keyword"
          :nose-tag="result.nose.tag"
          :nose-keyword="result.nose.keyword"
          :mouth-tag="result.mouth.tag"
          :mouth-keyword="result.mouth.keyword"
          :shape-tag="result.shape.tag"
          :shape-keyword="result.shape.keyword"
        />
        <!-- <img src="@/assets/page-3-border-photo.svg" alt="" /> -->
      </div>
      <div class="bottom flex-1 d-flex flex-column align-center">
        <h2 class="title" v-if="result.fiveElement">
          【{{ result.fiveElement.motto[0] }}】【{{
            result.fiveElement.motto[1]
          }}】
        </h2>
        <div>
          <div class="text" v-if="result.shape">
            {{ result.shape.tag }}｜{{ result.shape.shortDesc }}
          </div>
          <div class="text" v-if="result.brow">
            {{ result.brow.tag }}｜{{ result.brow.shortDesc }}
          </div>
          <div class="text" v-if="result.eye">
            {{ result.eye.tag }}｜{{ result.eye.shortDesc }}
          </div>
          <div class="text" v-if="result.nose">
            {{ result.nose.tag }}｜{{ result.nose.shortDesc }}
          </div>
          <div class="text" v-if="result.mouth">
            {{ result.mouth.tag }}｜{{ result.mouth.shortDesc }}
          </div>
        </div>
      </div>
    </section>
    <section id="card2" class="card d-flex flex-column align-center">
      <div class="top d-flex justify-center align-center">
        <page3-title
          class="w-100"
          v-if="result.score"
          :score="result.score"
          :category="result.style"
        />
        <!-- <img class="w-100" src="@/assets/page-3-title.svg" alt="" /> -->
      </div>
      <div class="middle d-flex justify-center align-center">
        這些商品適合你
      </div>
      <div class="bottom flex-1">
        <div
          class="prod d-flex align-center"
          v-for="(product, index) in recommendProduct"
          :key="product.id"
          @click="selectForYou(index)"
        >
          <div
            class="img"
            :style="{
              backgroundImage: 'url(' + getProductImg(product.id) + ')'
            }"
          ></div>
          <!-- <img
            class="image"
            src="https://dummyimage.com/96x92/000/fff"
            alt=""
          /> -->
          <div class="info flex-1">{{ product.name }}</div>
          <div class="button-wrapper">
            <img
              class="button-hover"
              src="@/assets/page-3-next.svg"
              alt=""
              v-if="index == 0"
            />
            <img
              class="button"
              src="@/assets/page-3-chooseme.svg"
              @click="toDetail(product.id)"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="card3" class="card d-flex flex-column align-center">
      <h1 class="title">周末野餐適合</h1>
      <div class="image-wrapper flex-1">
        <div
          v-if="result.weekendItem"
          class="img"
          :style="{
            backgroundImage:
              'url(' + getProductImg(thisIsForYouProduct.id) + ')'
          }"
        ></div>
        <!-- <div
          v-if="result.weekendItem"
          class="img"
          :style="{
            backgroundImage: 'url(' + getWeekendProductImg() + ')'
          }"
        ></div> -->
      </div>
      <div class="button-wrapper d-flex">
        <img
          class="button"
          src="@/assets/page-3-recamera.svg"
          @click="toHome()"
        />
        <img class="button" src="@/assets/page-3-more.svg" @click="toMore()" />
      </div>
    </section>
  </main>
</template>

<script>
import store from '@/store'

import Page3Attributes from '@/components/Page3Attributes'
import Page3Title from '@/components/Page3Title'

// const MOCK_IMAGE_ID = 'ttxi282edd6cad534e959d5910f02c0d5c68'

export default {
  name: 'Result',
  data() {
    return { result: {}, imgUrl: '', thisIsForyouIndex: 0 }
  },
  components: { Page3Title, Page3Attributes },
  mounted: function() {
    let vm = this
    vm.$store.commit('toggleLoading')
    // console.log(this.$route.params)
    let imageId = this.$route.params.imageId
    this.imgUrl =
      'https://upload-face-reading-must.taotaoxi.net/upload-picture/' +
      imageId +
      '.png'
    if ('undefined' === typeof this.$route.params.result) {
      store
        .dispatch('getResult2', { imageId: imageId, times: 0 })
        .then(function(res) {
          vm.result = res
          vm.$store.commit('toggleLoading')
          new Audio(require('../assets/vocal/3.mp3')).play()
        })
    } else {
      this.result = this.$route.params.result
      vm.$store.commit('toggleLoading')
    }
  },
  computed: {
    recommendProduct: function() {
      let products = []
      for (let id in this.result.defaultItem) {
        products.push(
          this.$store.getters.getProductById(this.result.defaultItem[id] + 1)
        )
      }
      return products
    },
    getProductsCount: function() {
      return this.$store.getters.getProducts().length
    },
    thisIsForYouProduct: function() {
      return this.recommendProduct[this.thisIsForyouIndex]
    }
  },
  methods: {
    setResult: function(data) {
      this.result = data
    },
    toHome: function() {
      this.$router.push({ name: 'Home' })
    },
    toMore: function() {
      this.$router.push({
        name: 'More',
        params: { imageId: this.$route.params.imageId }
      })
    },
    getProductImg: function(filename) {
      return require('../assets/product/' + filename + '.jpg')
    },
    getWeekendProductImg: function() {
      return require('../assets/product/' +
        padLeft(this.result.weekendItem, 4) +
        '.jpg')
    },
    toDetail: function(productId) {
      this.$router.push({
        name: 'Detail',
        params: { productId: productId, imageId: this.$route.params.imageId }
      })
    },
    selectForYou: function(index) {
      this.thisIsForyouIndex = index
    }
  }
}

function padLeft(str, len) {
  str = '' + str
  return str.length >= len
    ? str
    : new Array(len - str.length + 1).join('0') + str
}
</script>

<style scoped>
#main {
  background-image: url('../assets/page-3-background.svg');
  /* background-image: url('./assert/page-3-background.jpg'); */
}
.card {
  position: fixed;
  top: 24.5%;
  height: 69.2vh;
  box-sizing: border-box;
  /* background-color: #c73434; */
  /* opacity: 0.8; */
}
#card1 {
  left: 8.5%;
  width: 33.8vw;
  padding: 3% 3% 0;
}
#card2 {
  left: 42.5%;
  width: 27.2vw;
  padding: 2% 1% 1%;
}
#card3 {
  left: 70%;
  width: 26.6vw;
  padding: 2.3%;
}
#card1 .top {
  width: 96%;
  height: 61%;
  position: relative;
  margin-top: 23px;
}
#card1 .bottom .title {
  margin: 27px 0 7px;
  font-size: 25px;
  color: #49aef0;
}
#card1 .bottom .text {
  color: #4659c3;
  font-size: 24px;
}
#card2 .top {
  height: 33%;
  width: 100%;
  padding: 5% 11% 8%;
  box-sizing: border-box;
}
#card2 .middle {
  height: 13%;
  width: 100%;
  font-size: 45px;
  color: #4659c3;
}
#card2 .bottom {
  width: 100%;
  padding: 6% 2%;
  padding-bottom: 0;
}
#card2 .prod {
  height: 30%;
}
#card2 .prod .info {
  font-size: 31px;
  margin-left: 25px;
  margin-right: 20px;
  line-height: 1;
}
#card2 .prod .button-wrapper {
  position: relative;
}
#card2 .prod .button-wrapper .button {
  height: 50%;
  width: 100px;
}
#card2 .prod .button-wrapper .button-hover {
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);
  width: 209px;
}
#card3 .title {
  color: #4659c3;
  margin: 0;
  font-size: 43px;
}
#card3 .image-wrapper {
  width: 100%;
  margin: 22px 0;
}
#card3 .button-wrapper {
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}
#card3 .button-wrapper .button {
  height: 102px;
}

#card2 .img {
  height: 100%;
  width: 20%;
  background-size: cover;
  background-position: center;
}
#card3 .img {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
</style>
