<template>
  <main id="main">
    <section id="card1" class="card d-flex flex-column">
      <h1 class="title">商 品 介 紹</h1>
      <div class="flex-1 d-flex justify-center align-center">
        <div
          class="product-img"
          :style="{
            backgroundImage: 'url(' + getProductImg(product.id) + ')'
          }"
        ></div>
        <!-- <img
          class="w-100"
          src="https://dummyimage.com/367x480/000/fff"
          alt=""
        /> -->
      </div>
    </section>
    <section id="card2" class="card d-flex flex-column align-center">
      <h1 class="title w-100" v-if="product.name">{{ product.name }}</h1>
      <div class="content w-100 flex-1">
        <table id="prod-info-table">
          <tr v-for="(item, index) in product.detail" :key="index">
            <td class="table-title">{{ item.title }}</td>
            <td>：</td>
            <td class="table-content">{{ item.content }}</td>
          </tr>
        </table>
      </div>
      <div class="d-flex">
        <div class="button-wrapper">
          <img
            class="button"
            src="@/assets/page-5-cancel1.svg"
            @click="back()"
          />
        </div>
        <div class="button-wrapper">
          <!-- <img class="button-hover" src="@/assets/page-5-next.svg" alt="" /> -->
          <img
            class="button"
            src="@/assets/page-5-buy.svg"
            @click="openDialog()"
          />
        </div>
      </div>
    </section>
    <div id="dialog1" class="dialog" v-show="showDialog">
      <img class="bg" src="@/assets/page-5-dialog1.svg" alt="" />
      <div class="button-wrapper d-flex justify-center align-center">
        <img
          class="button"
          src="@/assets/page-5-cancel2.svg"
          @click="cancelDialog()"
        />
        <img
          class="button"
          src="@/assets/page-5-check.svg"
          @click="openDialog2()"
        />
      </div>
    </div>
    <div id="dialog2" class="dialog" v-show="showDialog2">
      <img class="bg" src="@/assets/page-5-dialog2.svg" alt="" />
      <div class="button-wrapper d-flex justify-center align-center">
        <img class="button" src="@/assets/page-5-exit.svg" @click="toHome()" />
      </div>
    </div>
  </main>
</template>

<script>
// import Page7Price from '@/components/Page7Price'

export default {
  name: 'Detail',
  data() {
    return {
      showDialog: false,
      showDialog2: false
    }
  },
  // components: { Page7Price },
  mounted: function() {
    new Audio(require('../assets/vocal/4.mp3')).play()
  },
  computed: {
    product: function() {
      return this.$store.getters.getProductById(this.$route.params.productId)
    }
  },
  methods: {
    openDialog() {
      new Audio(require('../assets/vocal/4.mp3')).play()
      this.showDialog = true
    },
    cancelDialog() {
      this.showDialog = false
    },
    openDialog2() {
      this.sendTrace()
      new Audio(require('../assets/vocal/6.mp3')).play()
      this.showDialog = false
      this.showDialog2 = true
    },
    toHome: function() {
      this.$router.push({ name: 'Home' })
    },
    getProductImg: function(filename) {
      return require('../assets/product/' + filename + '.jpg')
    },
    back: function() {
      this.$router.go(-1)
    },
    sendTrace: function() {
      this.$store.dispatch('postTrace', {
        category: this.product.category,
        imageId: this.$route.params.imageId,
        productId: this.$route.params.productId,
        productName: this.product.name
      })
    }
  }
}
</script>

<style scoped>
#main {
  background-image: url('../assets/page-5-background.svg');
  /* background-image: url('@/assets/page-5-background-2.jpg'); */
}
/* dialig */
.dialog {
  position: fixed;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 755px;
  z-index: 20;
}
.dialog .button-wrapper {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.dialog .button-wrapper .button {
  height: 65px;
  margin: 0 40px;
}
/* cards */
.card {
  position: fixed;
  top: 30%;
  height: 61vh;
  box-sizing: border-box;
  /* background: #868686; */
  /* opacity: 0.8; */
}
#card1 {
  left: 10.5%;
  width: 29vw;
  padding: 1.5% 5%;
}
#card1 .title {
  color: #4659c3;
  font-size: 60px;
  margin: 0;
  font-weight: normal;
  text-align: center;
}
#card2 {
  left: 40.5%;
  width: 50vw;
  padding: 2% 2.5%;
}
#card2 .title {
  background-color: #4659c3;
  color: white;
  padding: 2px;
  text-align: center;
  font-size: 41px;
  margin: 0;
  letter-spacing: 5px;
}
#prod-info-table {
  color: black;
  font-weight: bold;
  font-size: 28px;
  width: 100%;
  table-layout: fixed;
}

#prod-info-table td {
  padding: 6px 0;
}
#prod-info-table td:first-child {
  width: 20%;
  text-align: right;
}
#prod-info-table td:nth-child(3) {
  width: 75%;
  text-align: center;
}
#card2 .button-wrapper {
  position: relative;
}
#card2 .button-wrapper .button {
  height: 60px;
  margin: 0 15px;
}
#card2 .button-wrapper .button-hover {
  position: absolute;
  top: -115px;
  width: 265px;
  left: 50%;
  transform: translateX(-50%);
}
.product-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.content {
  overflow-y: scroll;
  overflow-x: hidden;
}

.table-content {
  text-align: justify !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}
</style>
