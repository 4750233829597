<template>
  <main id="main">
    <div id="table-wrapper" class="tableFixHead">
      <table id="table" border="1">
        <thead>
          <tr>
            <th>項目名稱</th>
            <th>面相類型</th>
            <th>分析數量</th>
          </tr>
        </thead>
        <tbody v-if="prepared">
          <tr v-for="(item, index) in result[0].child" :key="item.name">
            <td :rowspan="result[0].child.length" v-if="index === 0">
              {{ result[0].name }}
            </td>
            <td class="d-none" v-else></td>
            <!-- <td>{{ item.parent }}型人</td> -->
            <td>{{ item.name }}</td>
            <td>{{ item.ratio }}%</td>
          </tr>
          <tr v-for="(item, index) in result[1].child" :key="item.name">
            <td :rowspan="result[1].child.length" v-if="index === 0">
              {{ result[1].name }}
            </td>
            <td class="d-none" v-else></td>
            <td>{{ item.name }}</td>
            <td>{{ item.ratio }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <a
      id="export"
      target="_blank"
      href="https://api.taotaoxi.net/face-reading-must/retail-statistics"
    >
      匯出
    </a>
  </main>
</template>

<script>
export default {
  name: 'Statistics',
  data() {
    return { result: [], prepared: false }
  },
  mounted: function() {
    let vm = this
    vm.$store.commit('toggleLoading')
    this.$store.dispatch('getStatistics').then(function(data) {
      data[1].child.sort(function(a, b) {
        return a.name - b.name
      })
      vm.result = data
      vm.prepared = true
      vm.$store.commit('toggleLoading')
    })
  }
}
</script>

<style scoped>
/* https://stackoverflow.com/questions/21168521/table-fixed-header-and-scrollable-body */
.tableFixHead {
  overflow-y: auto;
  height: 100px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
/* th     { background:#eee; } */
#main {
  background-image: url('../assets/page-6-background.svg');
  /* background-image: url('./assert/page-6-background.jpg'); */
}
.flex-v-h-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* table */
#table-wrapper {
  position: absolute;
  top: 28%;
  width: 87.3%;
  left: 8.6%;
  height: 63.3vh;
  /* opacity: 0.8; */
}
.tableFixHead {
  height: 100%;
}
#table th,
#table td {
  border: 3px solid #48aef0;
  border-left: none;
  text-align: center;
  background: white;
  padding: 0;
}
#table th {
  background: #48aef0;
  color: white;
  height: 60px;
  margin: 0;
  text-align: center;
  font-size: 35px;
  font-weight: normal;
}
#table th::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5px;
  left: 0;
  background: inherit;
  z-index: -1;
}
#table td {
  background-color: inherit;
  color: #2d2b5b;
  font-size: 28px;
  font-weight: bold;
  padding: 2px 0;
}

#table td:nth-child(1) {
  width: 19%;
}

#table td:nth-child(2) {
  width: 25%;
}

#table td:nth-child(3) {
  width: 22%;
}
/* export button*/
#export {
  position: fixed;
  right: 3.5%;
  bottom: 2.2%;
  color: white;
  background-color: #fe4b1c;
  font-size: 30px;
  border-radius: 19px;
  padding: 5px 35px;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
}
</style>
