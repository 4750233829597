<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 148.04 57"
    @click="$emit('back')"
  >
    <g id="圖層_2" data-name="圖層 2">
      <rect class="cls-1" width="148.04" height="57" />
      <!-- <path
        class="cls-2"
        d="M33.79,25.33V24.21H25V22.37h8.78V21.1c-2.2.12-4.53.21-7,.3l-.37-1.74q9-.29,15.89-1L43,20.46c-2.18.18-4.53.37-7,.53v1.38h8.83v1.84H36v1.12H43v7.92H36v1.21h8v1.73H36V37.5h9.17v1.86H24.69V37.5h9.1V36.19H25.84V34.46h8V33.25h-7V25.33Zm0,3.11V27.06H28.85v1.38Zm0,1.65H28.85v1.43h4.94ZM36,28.44h4.94V27.06H36Zm4.94,1.65H36v1.43h4.94Z"
      />
      <path
        class="cls-2"
        d="M55.88,21.43a18.71,18.71,0,0,0-1.15-2.28l2.06-.58A24.29,24.29,0,0,1,58,21.43h3v2H52.31v-2Zm4.41,16.69a19.18,19.18,0,0,0,5.45-2.74,12,12,0,0,1-2.76-5H61.67V28.3H71.76v2a12.81,12.81,0,0,1-2.89,5.22,21,21,0,0,0,4.71,2.6L72.41,40a22.6,22.6,0,0,1-5.06-3.09,19.43,19.43,0,0,1-6,3.06l-1.1-1.7v.21H55v1H53v-8h7.31Zm-.18-13.27v1.89h-7V24.85Zm0,3.18v1.9H53.18V28Zm-1.82,8.53V33.38H55v3.18ZM70.55,19.4v4.83c0,.51.29.78.92.78h1.44v2h-2c-1.68,0-2.51-.78-2.51-2.3V21.45h-3v.76a6,6,0,0,1-3.22,5.42l-1.45-1.58a4,4,0,0,0,2.5-3.61v-3ZM67.23,34.05a12.07,12.07,0,0,0,2.4-3.63H65.05A10,10,0,0,0,67.23,34.05Z"
      /> -->
      <text transform="matrix(1 0 0 1 11 38)" class="cls-2">
        回上頁
      </text>
      <path
        class="cls-2"
        transform="matrix(1 0 0 1 11 0)"
        d="M117.26,20.2h-8.05V13.61a1.44,1.44,0,0,0-2.38-1.09L91.5,25.66a1.43,1.43,0,0,0,0,2.18L106.83,41a1.45,1.45,0,0,0,2.38-1.1V33.3h8.05a5.09,5.09,0,0,1,5,3.11,9.69,9.69,0,0,1,.84,3.49,1.44,1.44,0,1,0,2.87,0V28.94a8.75,8.75,0,0,0-8.74-8.74Zm5.87,12.45a8.13,8.13,0,0,0-5.87-2.22h-9.49a1.44,1.44,0,0,0-1.44,1.43v4.91l-11.68-10,11.68-10v8.55a1.44,1.44,0,1,0,2.88,0V23.08h8.05a5.88,5.88,0,0,1,5.87,5.86v3.71Z"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
  fill: #bd272d;
}
.cls-2 {
  fill: #fff;
  font-size: 28px;
  font-weight: bold;
}
</style>
