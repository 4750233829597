<template>
  <main id="main">
    <section id="video">
      <video height="100%" id="camera"></video>
      <div
        class="count-down-num"
        :style="countDownNumAnimation"
        v-show="showCountdown"
      >
        {{ countDownNum }}
      </div>
    </section>
    <section id="take-shot">
      <img
        class="button"
        src="@/assets/page-1-camera.svg"
        @click="countdown()"
      />
    </section>
  </main>
  <!-- <main id="main">
    <section id="card" class="d-flex flex-column">
      <div class="top flex-1">
        <video height="100%" id="camera"></video>
        <div
          class="count-down-num"
          :style="countDownNumAnimation"
          v-show="showCountdown"
        >
          {{ countDownNum }}
        </div>
      </div>
      <div class="bottom d-flex align-center justify-center">
        <img
          class="button"
          src="@/assets/page-1-camera.svg"
          @click="countdown()"
        />
      </div>
    </section>
  </main> -->
</template>

<script>
const VIDEO_ID = 'camera'

// let localMediaStream
let taking = false

export default {
  name: 'Home',
  data() {
    return {
      countDownNum: null,
      countDownNumAnimation: null,
      showCountdown: false
    }
  },
  components: {},
  mounted: function() {
    new Audio(require('../assets/vocal/1.mp3')).play()
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {}
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        // 首先，如果有getUserMedia的话，就获得它
        var getUserMedia =
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia

        // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
        if (!getUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser')
          )
        }

        // 否则，为老的navigator.getUserMedia方法包裹一个Promise
        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }
    const constraints = {
      video: {
        width: { ideal: 1280 }
      },
      audio: false
    }
    let promise = navigator.mediaDevices.getUserMedia(constraints)
    promise
      .then(stream => {
        // const track = stream.getVideoTracks()[0]
        let v = document.getElementById(VIDEO_ID)
        // 旧的浏览器可能没有srcObject
        // localMediaStream = stream
        if ('srcObject' in v) {
          v.srcObject = stream
        } else {
          // 防止再新的浏览器里使用它，应为它已经不再支持了
          v.src = window.URL.createObjectURL(stream)
        }
        v.onloadedmetadata = function() {
          v.play()
        }
      })
      .catch(() => {
        // console.error(err.name + ': ' + err.message)
      })
  },
  methods: {
    countdown: function() {
      if (taking === false) {
        new Audio(require('../assets/vocal/0.mp3')).play()
        taking = true
        this.showCountdown = true
        this.countDownNum = 3
        this.countDownNumAnimation = 'animation: bigNum 1s forwards;'
        setTimeout(
          function() {
            this.countDownNumAnimation = ''
            this.countDownNum = ''
          }.bind(this),
          700
        )
        setTimeout(
          function() {
            this.countDownNumAnimation = ''
            this.countDownNum = 2
            this.countDownNumAnimation = 'animation: bigNum 1s forwards;'
          }.bind(this),
          1000
        )
        setTimeout(
          function() {
            this.countDownNumAnimation = ''
            this.countDownNum = ''
          }.bind(this),
          1700
        )
        setTimeout(
          function() {
            this.countDownNumAnimation = ''
            this.countDownNum = 1
            this.countDownNumAnimation = 'animation: bigNum 1s forwards;'
          }.bind(this),
          2000
        )
        setTimeout(
          function() {
            this.showCountdown = false
            this.takePicture()
          }.bind(this),
          3500
        )
      }
    },
    takePicture: function() {
      let vm = this
      let usersImage = new Image()
      usersImage.crossOrigin = 'Anonymous'
      let imgW = document.getElementById(VIDEO_ID).videoWidth
      let imgH = document.getElementById(VIDEO_ID).videoHeight

      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')

      canvas.width = imgW
      canvas.height = imgH

      ctx.translate(canvas.width, 0)
      ctx.scale(-1, 1)

      ctx.drawImage(document.getElementById(VIDEO_ID), 0, 0)
      let data = canvas.toDataURL('image/jpeg', 0.7)
      let files = dataURLtoFile(data, '')

      // vm.welcomeVideo = true
      // localMediaStream.getTracks()[0].stop()
      document.getElementById(VIDEO_ID).pause()
      // document.getElementById(VIDEO_ID).src = ''

      // console.log(files.size / 1024 + 'kb')

      usersImage.src = data

      usersImage.onload = function() {
        let widthOrHeight = null
        if (usersImage.width > usersImage.height) {
          widthOrHeight = usersImage.width / usersImage.height
          ctx.drawImage(
            usersImage,
            ((canvas.height / usersImage.height / usersImage.width) *
              canvas.width) /
              2 +
              12 * widthOrHeight,
            6 * widthOrHeight,
            canvas.width -
              (((canvas.height - 12) / usersImage.height / usersImage.width) *
                canvas.width) /
                2 -
              24 * widthOrHeight,
            canvas.height - 12 * widthOrHeight
          )
        } else {
          widthOrHeight = usersImage.height / usersImage.width
          ctx.drawImage(
            usersImage,
            6 * widthOrHeight,
            (((canvas.width - 12) / usersImage.width / usersImage.height) *
              canvas.height) /
              2 +
              12 * widthOrHeight,
            canvas.width - 12 * widthOrHeight,
            canvas.height -
              (((canvas.width - 12) / usersImage.width / usersImage.height) *
                canvas.height) /
                2 -
              24 * widthOrHeight
          )
        }
        // $rootScope.loading = true

        vm.imageUploadInfo(files, data, 0)
      }
    },
    imageUploadInfo: function(files, data, times) {
      // console.log(times)
      let vm = this
      vm.$store.commit('startLoading')
      if (times < 5) {
        vm.$store.dispatch('getImageUploadInfo').then(
          function(res) {
            if (typeof res !== 'undefined' && res !== null && res !== '') {
              // let imageId = res.imageId
              const account = {
                name: 'uploadfacereadingmust',
                sas: res.sas
              }
              const blobUri =
                'https://' + account.name + '.blob.core.windows.net'
              const blobService = AzureStorage.Blob.createBlobServiceWithSas(
                blobUri,
                account.sas
              )

              blobService.createBlockBlobFromBrowserFile(
                'upload-picture',
                res.imageId + '.png',
                files,
                { contentSettings: { contentType: 'image/png' } },
                error => {
                  if (error) {
                    // Handle blob error
                  } else {
                    // console.log('Upload is successful')
                    taking = false
                    vm.$store.commit('endLoading')
                    vm.$router.push({
                      name: 'Loading',
                      params: { imageId: res.imageId }
                    })
                  }
                }
              )
            } else {
              setTimeout(function() {
                vm.imageUploadInfo(files, data, times + 1)
              }, 1000)
            }
          },
          function() {
            setTimeout(function() {
              vm.imageUploadInfo(files, data, times + 1)
            }, 1000)
          }
        )
      } else {
        taking = false
        vm.$store.commit('endLoading')
        vm.$store.commit('setError')
        setTimeout(function() {
          vm.$store.commit('cleanError')
          document.getElementById(VIDEO_ID).play()
        }, 5000)
      }
    }
  }
}

// to change Blob to File
function blobToFile(theBlob, fileName) {
  const b = theBlob
  b.lastModifiedDate = new Date()
  b.name = fileName
  return b
}

function dataURLtoFile(dataurl, filename) {
  //将base64转换为文件
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  let file
  if (!navigator.userAgent.match('Edge')) {
    // detect if not Edge
    file = new File([u8arr], filename, { type: mime })
  } else {
    file = new Blob([u8arr], { type: mime })
    file = blobToFile(file, filename)
  }
  return file
}

// function loadImage(url) {
//   return new Promise((resolve, reject) => {
//     let img = new Image()
//     img.onload = () => resolve(img)
//     img.onerror = reject
//     img.src = url
//   })
// }
</script>
<style scoped>
#main {
  background-image: url('../assets/page-1-background.svg');
}

#loading-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

#loading-bg .anim-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 100px;
}
#main {
  width: 100%;
  height: 100%;
}
#video {
  position: fixed;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  height: 44%;
}
#take-shot {
  position: fixed;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
}
#take-shot .button {
  height: 82px;
}

.count-down-num {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -50%) scale(1, 1);
  font-size: 110px;
  color: #ffffff;
  transform-origin: 50% 50%;
}
#camera {
  transform: translateX(-50%) rotateY(180deg);
  -webkit-transform: translateX(-50%) rotateY(180deg);
  -moz-transform: translateX(-50%) rotateY(180deg);
  animation: show 3s forwards;
  position: absolute;
  left: 50%;
}
</style>
