<template>
  <main id="main">
    <section id="video">
      <canvas id="user-image" ref="userImage"></canvas>
      <canvas id="animation-canvas" ref="animationCanvas"></canvas>
    </section>
    <section id="camera">
      <img class="button" src="@/assets/page-2-recamera.svg" alt="" />
    </section>
    <section id="result">
      <div class="row d-flex" v-show="showText[0]">
        <span v-if="result.upperFaceKeyword">
          上庭 | {{ result.upperFaceKeyword }}
        </span>
        <span v-show="showText[1]">完成</span>
      </div>
      <div class="row d-flex" v-show="showText[2]">
        <span v-if="result.middleFaceKeyword">
          中停 | {{ result.middleFaceKeyword }}
        </span>
        <span v-show="showText[3]">完成</span>
      </div>
      <div class="row d-flex" v-show="showText[4]">
        <span v-if="result.bottomFaceKeyword">
          下停 | {{ result.bottomFaceKeyword }}
        </span>
        <span v-show="showText[5]">完成</span>
      </div>
      <div class="row d-flex" v-show="showText[6]">
        <span v-if="result.brow"> 定位眉毛 | {{ result.brow.tag }} </span>
        <span v-show="showText[7]">完成</span>
      </div>
      <div class="row d-flex" v-show="showText[8]">
        <span v-if="result.eye"> 定位眼睛 | {{ result.eye.tag }} </span>
        <span v-show="showText[9]">完成</span>
      </div>
      <div class="row d-flex" v-show="showText[10]">
        <span v-if="result.nose"> 定位鼻子 | {{ result.nose.tag }} </span>
        <span v-show="showText[11]">完成</span>
      </div>
      <div class="row d-flex" v-show="showText[12]">
        <span v-if="result.mouth"> 定位唇口 | {{ result.mouth.tag }} </span>
        <span v-show="showText[13]">完成</span>
      </div>
      <h2 class="subtitle" v-show="showText[14]">【 完成評測 】</h2>
    </section>
  </main>
</template>

<script>
import store from '@/store'

export default {
  name: 'Loading',
  data() {
    return {
      result: {},
      imageId: null,
      showText: [],
      showDone: false
    }
  },
  mounted: function() {
    let vm = this
    vm.$store.commit('toggleLoading')
    this.imageId = this.$route.params.imageId
    let url =
      'https://upload-face-reading-must.taotaoxi.net/upload-picture/' +
      this.imageId +
      '.png'

    let p1 = loadImage(url).then(function(img) {
      let userImgCanvas = vm.$refs.userImage
      let userImgCtx = userImgCanvas.getContext('2d')

      let animationCanvas = vm.$refs.animationCanvas

      userImgCanvas.width = img.width
      userImgCanvas.height = img.height
      animationCanvas.width = img.width
      animationCanvas.height = img.height

      userImgCtx.drawImage(img, 0, 0)
    })

    let p2 = store
      .dispatch('getResult2', { imageId: this.imageId, times: 0 })
      .then(
        function(res) {
          // console.log(res)
          vm.result = res
        },
        function(err) {
          throw err
        }
      )

    Promise.all([p1, p2]).then(
      () => {
        vm.$store.commit('toggleLoading')
        new Audio(require('../assets/vocal/2.mp3')).play()
        // text scroll start
        for (let i = 0; i < 15; i++) {
          setTimeout(() => {
            vm.showText.push(true)
          }, i * 400)
        }

        // animation start
        let userImageCanvas = vm.$refs.userImage
        let animationCanvas = vm.$refs.animationCanvas
        let ctx4 = animationCanvas.getContext('2d')
        let faceDetect = vm.result.position

        //繪製底線方格
        drawBaseBackground(userImageCanvas)

        let landmarks = vm.result.landmarks
        let timer,
          flag = true,
          flag1 = true,
          flag2 = false,
          flag3 = false,
          flagDone = false

        peak = landmarks[peakArr[0]].y
        lowestY = landmarks[lowestArr[0]].y
        eyebrow = landmarks[eyebrowArr[0]].y
        eyeTop = landmarks[eyeArr[0]].y
        eyeBottom = landmarks[eyeArr[0]].y
        nose = landmarks[noseArr[0]].y
        mouthTop = landmarks[mouthArr[0]].y
        mouthBottom = landmarks[mouthArr[0]].y

        for (let i = 0; i < peakArr.length; i++) {
          if (landmarks[peakArr[i]].y < peak) {
            //最高点y  最高点x 57
            peak = landmarks[peakArr[i]].y
          }
        }
        for (let i = 0; i < lowestArr.length; i++) {
          if (landmarks[lowestArr[i]].y > lowestY) {
            //最低点y
            lowestX = landmarks[lowestArr[i]].x
            lowestY = landmarks[lowestArr[i]].y
          }
        }
        for (let i = 0; i < eyebrowArr.length; i++) {
          if (landmarks[eyebrowArr[i]].y > eyebrow) {
            //最低点y
            eyebrow = landmarks[eyebrowArr[i]].y
          }
        }
        for (let i = 0; i < eyeArr.length; i++) {
          if (landmarks[eyeArr[i]].y < eyeTop) {
            //最高点y
            eyeTop = landmarks[eyeArr[i]].y
          }
        }
        for (let i = 0; i < eyeArr.length; i++) {
          if (landmarks[eyeArr[i]].y > eyeBottom) {
            //最低点y
            eyeBottom = landmarks[eyeArr[i]].y
          }
        }
        for (let i = 0; i < noseArr.length; i++) {
          if (landmarks[noseArr[i]].y > nose) {
            //最低点y
            nose = landmarks[noseArr[i]].y
          }
        }
        for (let i = 0; i < mouthArr.length; i++) {
          if (landmarks[mouthArr[i]].y < mouthTop) {
            //最高点y
            mouthTop = landmarks[mouthArr[i]].y
          }
        }
        for (let i = 0; i < mouthArr.length; i++) {
          if (landmarks[mouthArr[i]].y > mouthBottom) {
            //最低点y
            mouthBottom = landmarks[mouthArr[i]].y
          }
        }

        cancelAnimationFrame(timer)
        timer = requestAnimationFrame(step)

        function step() {
          if (flag) {
            if (flag1) {
              drawLine()
            }
            if (flag2) {
              drawArcLine()
            }
            if (flag3) {
              drawArc()
            }
            timer = requestAnimationFrame(step)
          } else {
            cancelAnimationFrame(timer)
          }
        }

        function drawLine() {
          ctx4.lineWidth = 1
          ctx4.strokeStyle = '#ffffff'
          ctx4.fillStyle = '#ffffff'
          ctx4.font = animationCanvas.width / 22 + 'px  sans-serif'
          ctx4.globalAlpha = 1
          ctx4.beginPath()
          drawWhiteLineX += add
          drawLongLine(landmarks[30].x, peak, drawWhiteLineX - add)
          drawLongLine(landmarks[30].x, landmarks[30].y, drawWhiteLineX - add)
          drawLongLine(landmarks[30].x, lowestY, drawWhiteLineX - add)
          ctx4.closePath()
          ctx4.beginPath()
          if (
            drawShortLine(
              landmarks[30].x,
              (landmarks[30].y - peak) / 2 + peak,
              landmarks[30].y,
              peak,
              drawWhiteLineX
            )
          ) {
            drawArrow(landmarks[30].x, landmarks[30].y, 'bottom')
            drawArrow(landmarks[30].x, peak, 'top')
          }
          if (
            drawShortLine(
              lowestX,
              (lowestY - landmarks[30].y) / 2 + landmarks[30].y,
              lowestY,
              landmarks[30].y,
              drawWhiteLineX
            )
          ) {
            drawArrow(lowestX, lowestY, 'bottom')
            drawArrow(lowestX, landmarks[30].y, 'top')
          }
          if (drawWhiteLineX > faceDetect.width / 2) {
            flag1 = false
            setTimeout(() => {
              flag2 = true
              drawWhiteLineX = 0
              // scrollText(4)
              clearCanvas(animationCanvas)
            }, 1000)
          }
        }

        function drawArcLine() {
          ctx4.lineWidth = 1
          ctx4.strokeStyle = '#ffffff'
          ctx4.fillStyle = '#ffffff'
          ctx4.font = animationCanvas.width / 22 + 'px  sans-serif'
          ctx4.globalAlpha = 1
          ctx4.beginPath()
          drawWhiteLineX += add
          //眉高
          drawLongLine(landmarks[30].x, peak)
          //眉低
          drawLongLine(landmarks[30].x, eyebrow)
          //眼高
          drawLongLine(landmarks[30].x, eyeTop)
          //眼低
          drawLongLine(landmarks[30].x, eyeBottom)
          //鼻低
          drawLongLine(landmarks[30].x, nose)
          //嘴高
          drawLongLine(landmarks[30].x, mouthTop)
          //嘴低
          drawLongLine(landmarks[30].x, mouthBottom)
          //脸低
          drawLongLine(landmarks[30].x, lowestY)

          ctx4.closePath()
          ctx4.beginPath()
          drawShortLine(
            landmarks[13].x,
            peak + (lowestY - peak) / 2,
            lowestY,
            peak,
            drawWhiteLineX
          )
          drawShortLine(
            landmarks[17].x,
            peak + (lowestY - peak) / 2,
            lowestY,
            peak,
            drawWhiteLineX
          )
          drawShortLine(
            landmarks[30].x,
            peak + (lowestY - peak) / 2,
            lowestY,
            peak,
            drawWhiteLineX
          )
          drawShortLine(
            landmarks[34].x,
            peak + (lowestY - peak) / 2,
            lowestY,
            peak,
            drawWhiteLineX
          )
          if (drawWhiteLineX > (faceDetect.width / 5) * 3) {
            flag2 = false
            setTimeout(() => {
              clearCanvas(animationCanvas)
              drawWhiteLineX = 0
              flag3 = true
            }, 1000)
          }
        }

        function drawArc() {
          for (let i = 0; i < animationArr.length; i++) {
            // console.log(animationArr[0][i])
            // drawWhiteLineX += faceDetect.width / 2 / animationArr[0][i].length
            drawDot(animationArr[i], 0)
          }
          if (!flagDone) {
            flagDone = true
            setTimeout(() => {
              // scrollText(2)
              flag3 = false
              drawWhiteLineX = 0
              vm.showDone = true
              setTimeout(() => {
                vm.$router.push({
                  name: 'Result',
                  params: { imageId: vm.imageId, result: vm.result }
                })
              }, 1000)
            }, 1200)
          }
        }

        function drawDot(x) {
          for (let i = 0; i < x.length - 1; i++) {
            setTimeout(() => {
              addLine(
                landmarks[x[i]].x,
                landmarks[x[i]].y,
                landmarks[x[i + 1]].x,
                landmarks[x[i + 1]].y
              )
            }, 100 * i)
          }
        }

        function addLine(x, y, prevX, prevY) {
          ctx4.lineWidth = 1
          ctx4.beginPath()
          ctx4.setLineDash([])
          ctx4.moveTo(x, y)
          ctx4.lineTo(prevX, prevY)
          ctx4.stroke()
        }

        function drawArrow(x, y, type) {
          ctx4.globalAlpha = 1
          if (type == 'bottom') {
            ctx4.beginPath()
            ctx4.moveTo(x, y)
            ctx4.lineTo(x - 3, y - 3)
            ctx4.lineTo(x + 3, y - 3)
            ctx4.closePath()
            ctx4.fill()
          } else {
            ctx4.beginPath()
            ctx4.moveTo(x, y)
            ctx4.lineTo(x - 3, y + 3)
            ctx4.lineTo(x + 3, y + 3)
            ctx4.closePath()
            ctx4.fill()
          }
        }

        function drawShortLine(drawX, drawY, doneBottomY, doneTopY, reAdd) {
          ctx4.globalAlpha = 1
          ctx4.setLineDash([3])
          if (drawY + drawWhiteLineX < doneBottomY) {
            ctx4.moveTo(drawX, drawY)
            ctx4.lineTo(drawX, drawY + reAdd)
            ctx4.stroke()
          } else {
            return true
          }
          if (drawY - drawWhiteLineX > doneTopY) {
            ctx4.moveTo(drawX, drawY)
            ctx4.lineTo(drawX, drawY - reAdd)
            ctx4.stroke()
          } else {
            return true
          }
        }

        function drawLongLine(drawX, drawY) {
          //drawX  画线动画X
          //drawY  画线动画Y
          ctx4.globalAlpha = drawWhiteLineX / ctx4.width
          ctx4.setLineDash([])
          ctx4.moveTo(drawX - 8, drawY)
          ctx4.lineTo(drawX + drawWhiteLineX - 8, drawY)
          ctx4.stroke()
          ctx4.moveTo(drawX - 8, drawY)
          ctx4.lineTo(drawX - drawWhiteLineX - 8, drawY)
          ctx4.stroke()
        }
        // animation end

        // console.log('all done')
        // 跳轉到結果頁面
        // vm.$router.push({
        //   name: 'Result',
        //   params: { imageId: vm.imageId, result: vm.result }
        // })
      },
      function() {
        // console.log(err)
        vm.$store.commit('toggleLoading')
        vm.$store.commit('setError')
      }
    )
  },
  updated: function() {},
  methods: {
    scroll: function(i) {
      this.showText[i] = true
    }
  }
}

let drawWhiteLineX = 0,
  // faceDetect,
  peakArr = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
  peak,
  lowestArr = [5, 6, 7, 8, 9, 10, 11],
  lowestY,
  lowestX,
  eyebrowArr = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
  eyebrow,
  eyeArr = [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
  eyeTop,
  eyeBottom,
  noseArr = [31, 32, 33, 34, 35],
  nose,
  mouthArr = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
  mouthTop,
  mouthBottom,
  animationArr = [
    [17, 18, 19, 20, 21, 17],
    [22, 23, 24, 25, 26, 22],
    [36, 37, 38, 39, 40, 41, 36],
    [42, 43, 44, 45, 46, 47, 42],
    [28, 31, 32, 33, 34, 35, 28],
    [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 48],
    [0, 1, 2, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16]
  ]
let add = 2

function clearCanvas(canvas) {
  let ctx4 = canvas.getContext('2d')
  ctx4.clearRect(0, 0, canvas.width, canvas.height)
}

function loadImage(url) {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = url
  })
}

function drawBaseBackground(canvas) {
  let ctx = canvas.getContext('2d')
  // animation start
  let bgWhirte = [
    canvas.width / 5,
    canvas.width / 4,
    canvas.width / 2,
    (canvas.width / 4) * 3,
    (canvas.width / 5) * 4
  ]
  let bgHeightWhirte = [
    (canvas.height / 20) * 3,
    (canvas.height / 20) * 4,
    (canvas.height / 20) * 9,
    canvas.height / 2,
    (canvas.height / 20) * 13,
    (canvas.height / 20) * 14
  ]
  ctx.lineWidth = 1
  ctx.strokeStyle = '#ffffff'
  ctx.fillStyle = '#ffffff'
  for (let i = 0; i < bgWhirte.length; i++) {
    ctx.beginPath()
    ctx.setLineDash([])
    ctx.moveTo(bgWhirte[i], 0)
    ctx.lineTo(bgWhirte[i], canvas.height)
    ctx.stroke()
  }
  for (let i = 0; i < bgHeightWhirte.length; i++) {
    ctx.beginPath()
    ctx.setLineDash([])
    ctx.moveTo(0, bgHeightWhirte[i])
    ctx.lineTo(canvas.width, bgHeightWhirte[i])
    ctx.stroke()
  }
}

window.requestAnimationFrame = (function() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60)
    }
  )
})()
window.cancelAnimationFrame =
  window.cancelRequestAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.webkitCancelRequestAnimationFrame ||
  window.mozCancelAnimationFrame ||
  window.mozCancelRequestAnimationFrame ||
  window.msCancelAnimationFrame ||
  window.msCancelRequestAnimationFrame ||
  window.oCancelAnimationFrame ||
  window.oCancelRequestAnimationFrame ||
  window.clearTimeout
</script>

<style scoped>
#main {
  background-image: url('../assets/page-2-background.svg');
  /* background-image: url('./assert/page-2-background.jpg'); */
}
#video {
  position: fixed;
  top: 51%;
  left: 6%;
  transform: translateY(-50%);
  width: 42%;
  height: 41%;
}
#camera {
  position: fixed;
  top: 83%;
  left: 21.9%;
}
#camera .button {
  height: 82px;
}
#result {
  position: fixed;
  top: 33%;
  right: 0;
  width: 51%;
  padding: 0 8.5%;
  box-sizing: border-box;
}
#result .row {
  justify-content: space-between;
  color: #48adef;
  font-size: 42px;
  font-weight: bold;
  margin: 13px 0;
}
#result .subtitle {
  color: #4c5dc2;
  font-size: 70px;
  font-weight: normal;
  text-align: center;
  margin: 0;
}

#user-image {
  position: relative;
  width: 100%;
}
#animation-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
