<template>
  <svg
    id="Слой_1"
    data-name="Слой 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384.96 159.46"
  >
    <rect class="cls-1" x="169.88" y="4.51" width="210.57" height="149.85" />
    <circle class="cls-2" cx="380.45" cy="154.36" r="4.51" />
    <path
      class="cls-2"
      d="M375.94,154.36a4.51,4.51,0,1,0,4.51-4.51A4.51,4.51,0,0,0,375.94,154.36Z"
    />
    <line class="cls-1" x1="237.44" y1="4.51" x2="237.44" y2="154.36" />
    <path
      class="cls-2"
      d="M232.93,4.51A4.51,4.51,0,1,1,237.44,9,4.51,4.51,0,0,1,232.93,4.51Z"
    />
    <path
      class="cls-2"
      d="M232.93,154.36a4.51,4.51,0,1,0,4.51-4.51A4.51,4.51,0,0,0,232.93,154.36Z"
    />
    <line class="cls-1" x1="169.88" y1="54.92" x2="237.44" y2="54.92" />
    <circle class="cls-2" cx="169.88" cy="54.92" r="4.51" />
    <path
      class="cls-2"
      d="M237.44,59.43a4.51,4.51,0,1,0-4.51-4.51A4.51,4.51,0,0,0,237.44,59.43Z"
    />
    <line class="cls-1" x1="169.88" y1="105.81" x2="237.44" y2="105.81" />
    <circle class="cls-2" cx="169.88" cy="105.81" r="4.51" />
    <path
      class="cls-2"
      d="M237.44,110.32a4.52,4.52,0,1,0-4.51-4.51A4.51,4.51,0,0,0,237.44,110.32Z"
    />
    <line class="cls-1" x1="237.44" y1="79.44" x2="249.89" y2="79.44" />
    <circle class="cls-2" cx="237.44" cy="79.44" r="4.51" />
    <circle class="cls-2" cx="249.89" cy="79.44" r="4.51" />
    <line class="cls-1" x1="306.36" y1="4.51" x2="306.36" y2="17.31" />
    <path
      class="cls-2"
      d="M301.85,4.51A4.51,4.51,0,1,1,306.36,9,4.51,4.51,0,0,1,301.85,4.51Z"
    />
    <circle class="cls-2" cx="306.36" cy="17.31" r="4.51" />
    <line class="cls-1" x1="306.36" y1="142.15" x2="306.36" y2="154.94" />
    <circle class="cls-2" cx="306.36" cy="142.15" r="4.51" />
    <circle class="cls-2" cx="306.36" cy="154.94" r="4.51" />
    <line class="cls-1" x1="367.81" y1="79.44" x2="380.45" y2="79.44" />
    <circle class="cls-2" cx="367.81" cy="79.44" r="4.51" />
    <circle class="cls-2" cx="380.45" cy="79.44" r="4.51" />
    <circle class="cls-3" cx="35.95" cy="39.94" r="35.95" />
    <circle class="cls-2" cx="35.95" cy="39.94" r="29.45" />
    <text class="cls-4" transform="translate(18.39 53.61)">購</text>
    <circle class="cls-3" cx="107.85" cy="39.94" r="35.95" />
    <circle class="cls-2" cx="107.85" cy="39.94" r="29.45" />
    <text class="cls-4" transform="translate(90.29 53.61)">買</text>
    <circle class="cls-3" cx="35.95" cy="116.45" r="35.95" />
    <circle class="cls-2" cx="35.95" cy="116.45" r="29.45" />
    <text class="cls-4" transform="translate(18.96 130.36)">風</text>
    <circle class="cls-3" cx="107.85" cy="116.45" r="35.95" />
    <circle class="cls-2" cx="107.85" cy="116.45" r="29.45" />
    <text class="cls-4" transform="translate(90.86 130.36)">格</text>
    <text class="cls-5" transform="translate(186.21 43.23)">
      {{ c.charAt(0) }}
    </text>
    <text class="cls-5" transform="translate(186.21 93.03)">
      {{ c.charAt(1) }}
    </text>
    <text class="cls-6" transform="translate(258.69 108.27)">{{ score }}</text>
    <text class="cls-5" transform="translate(186.21 142.83)">
      {{ c.charAt(2) }}
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      default: 99
    },
    category: {
      type: String,
      default: ''
    }
  },
  computed: {
    c: function() {
      return this.category
    }
  }
}
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #4659c3;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}
.cls-2,
.cls-5,
.cls-6 {
  fill: #4659c3;
}
.cls-3 {
  fill: #49aef0;
}
.cls-4 {
  font-size: 33.98px;
  fill: #fff;
}
.cls-4,
.cls-5,
.cls-6 {
  font-family: TaipeiSansTCBeta-Bold, Taipei Sans TC Beta;
  font-weight: 700;
}
.cls-5 {
  font-size: 33.75px;
}
.cls-6 {
  font-size: 84.37px;
}
</style>
