<template>
  <svg
    version="1.1"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 507.11 407.89"
    style="enable-background:new 0 0 516 378;"
    xml:space="preserve"
  >
    <defs>
      <pattern
        id="image"
        x="0"
        y="0"
        patternUnits="objectBoundingBox"
        height="1"
        width="1"
        :viewBox="viewBox"
        preserveAspectRatio="xMidYMid slice"
      >
        <image x="0" y="0" :href="imgUrl"></image>
      </pattern>
    </defs>
    <line class="cls-1" x1="130.93" y1="215.17" x2="95.82" y2="239.48" />
    <line class="cls-1" x1="249.31" y1="287.06" x2="249.31" y2="322.33" />
    <line class="cls-1" x1="372.05" y1="213.9" x2="428.32" y2="249" />
    <line class="cls-1" x1="69.97" y1="61.81" x2="122.65" y2="82.88" />
    <line class="cls-1" x1="375.54" y1="82.84" x2="415.53" y2="74.27" />
    <circle class="cls-2" cx="52.34" cy="52.34" r="50.84" />
    <circle class="cls-2" cx="65.53" cy="260.19" r="50.84" />
    <circle class="cls-2" cx="454.77" cy="67.17" r="50.84" />
    <circle class="cls-2" cx="446.44" cy="261.16" r="50.84" />
    <circle class="cls-2" cx="249.31" cy="355.54" r="50.84" />
    <circle class="cls-1" cx="249.31" cy="145.24" r="140.76" />
    <circle class="st6" cx="249.31" cy="145.24" r="132.5" fill="url(#image)" />
    <text class="cls-3" transform="translate(427.06 90.35)">
      {{ eyeKeyword }}
    </text>
    <text class="cls-3" transform="translate(17.72 71.22)">
      {{ browKeyword }}
    </text>
    <text class="cls-3" transform="translate(413.06 283.56)">
      {{ noseKeyword }}
    </text>
    <text class="cls-3" transform="translate(219.52 384.23)">
      {{ shapeKeyword }}
    </text>
    <text class="cls-3" transform="translate(32.6 283.56)">
      {{ mouthKeyword }}
    </text>
    <text class="cls-4" transform="translate(215.5 354.83)">
      {{ shapeTag }}
    </text>
    <text
      class="cls-4"
      transform="translate(13.76 42.21)"
      v-if="browTag.length == 3"
    >
      {{ browTag }}
    </text>
    <text class="cls-4" transform="translate(25.76 42.21)" v-else>
      {{ browTag }}
    </text>
    <text
      class="cls-4"
      transform="translate(423.1 61.34)"
      v-if="eyeTag.length == 3"
    >
      {{ eyeTag }}
    </text>
    <text class="cls-4" transform="translate(435.1 61.34)" v-else>
      {{ eyeTag }}
    </text>
    <text
      class="cls-4"
      transform="translate(409.1 254.16)"
      v-if="noseTag.length == 3"
    >
      {{ noseTag }}
    </text>
    <text class="cls-4" transform="translate(421.1 254.16)" v-else>
      {{ noseTag }}
    </text>
    <text
      class="cls-4"
      transform="translate(28.64 254.16)"
      v-if="mouthTag.length == 3"
    >
      {{ mouthTag }}
    </text>
    <text class="cls-4" transform="translate(40.64 254.16)" v-else>
      {{ mouthTag }}
    </text>
  </svg>
</template>

<script>
// import { SSL_OP_NO_TLSv1_2 } from 'constants'
// const CX = 255.3
// const CY = 135.2
const CR = 132.5

export default {
  props: {
    imgUrl: {
      type: String,
      default:
        'https://uploadfacereading.blob.core.windows.net/upload-picture/ttxiae94eac26d304b4697ce05e366313783.png'
    },
    faceTop: {
      type: Number,
      default: 93
    },
    faceLeft: {
      type: Number,
      default: 525
    },
    faceWidth: {
      type: Number,
      default: 341
    },
    faceHeight: {
      type: Number,
      default: 379
    },
    eyeTag: { type: String, default: '定眼' },
    eyeKeyword: { type: String, default: '心思深沉' },
    browTag: { type: String, default: '點眉' },
    browKeyword: { type: String, default: '感情用事' },
    noseTag: { type: String, default: '仗義鼻' },
    noseKeyword: { type: String, default: '大方隨性' },
    mouthTag: { type: String, default: '智唇' },
    mouthKeyword: { type: String, default: '未雨綢繆' },
    shapeTag: { type: String, default: '金型人' },
    shapeKeyword: { type: String, default: '紀律分明' }
  },
  computed: {
    viewBox: function() {
      return (
        this.faceLeft +
        ' ' +
        this.faceTop +
        ' ' +
        this.faceWidth +
        ' ' +
        this.faceHeight
      )
    },
    userImageCenter: function() {
      let imgCenterX = this.faceLeft + this.faceWidth / 2
      let imgCenterY = this.faceTop + this.faceHeight / 2
      return { x: imgCenterX, y: imgCenterY }
    },
    userImageRatio: function() {
      let ratio = 1
      if (this.faceWidth > this.faceHeight) {
        ratio = (CR * 2) / this.faceHeight
      } else {
        ratio = (CR * 2) / this.faceWidth
      }
      return ratio
    }
  }
}
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke-width: 6px;
}
.cls-1,
.cls-2 {
  stroke: #49aef0;
  stroke-miterlimit: 10;
}
.cls-2 {
  fill: #49aef0;
  stroke-width: 3px;
}
.cls-3 {
  font-size: 16.08px;
  fill: #2c2b5a;
}
.cls-3,
.cls-4 {
  font-family: TaipeiSansTCBeta-Bold, Taipei Sans TC Beta;
  font-weight: 700;
}
.cls-4 {
  font-size: 24.13px;
  fill: #fff;
}
</style>
